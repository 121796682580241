import { BsMoonStars, BsPalette, BsSun } from 'react-icons/bs'
import { HiOutlineLanguage } from 'react-icons/hi2'

import Link from 'next/link'
import { useRouter } from 'next/router'
import { type TFunction, useTranslation } from 'next-i18next'

import cn from 'classnames'

import { useThemes } from '@/context/ThemeContext'
import { LANG } from '@/utils/constants/common'
import { COLORS, MAP_COLORS } from '@/utils/constants/themes'

import { type MenuComponentProps, type SettingMenu } from '../types'

const MenuButton = ({
  t,
  text,
  Icon,
  hidden,
  onClick,
}: SettingMenu & { t: TFunction }) => {
  const classes = cn(
    'my-transition flex w-full items-center gap-x-2 rounded-md px-2 py-1 text-primary-text',
    {
      hidden,
      'hover:bg-light/60 hover:text-accent dark:hover:bg-light/20':
        onClick != null,
    },
  )

  if (onClick == null) {
    return (
      <div className={classes}>
        <Icon size={18} />
        <span>{t(text)}</span>
      </div>
    )
  }

  return (
    <button className={classes} onClick={onClick}>
      <Icon size={18} />
      <span>{t(text)}</span>
    </button>
  )
}

const MenuComponent = ({
  isOpen = false,
  className,
  style,
  containerRef,
}: MenuComponentProps) => {
  const router = useRouter()
  const { t } = useTranslation('common')

  const { themes, changeColorHandler, changeThemeHandler } = useThemes()

  const SETTING_MENU: SettingMenu[] = [
    {
      text: 'dark_mode',
      Icon: BsMoonStars,
      hidden: themes.theme === 'dark',
      onClick: () => {
        changeThemeHandler('dark')
      },
    },
    {
      text: 'light_mode',
      Icon: BsSun,
      hidden: themes.theme === 'light',
      onClick: () => {
        changeThemeHandler('light')
      },
    },
    {
      text: 'change_color',
      Icon: BsPalette,
      hidden: false,
    },
    {
      text: 'switch_language',
      Icon: HiOutlineLanguage,
      hidden: false,
    },
  ]

  return (
    <div
      ref={containerRef}
      className={cn(
        className,
        'shadow-primary-md my-transition fixed z-10 flex w-max origin-bottom-right -translate-x-full -translate-y-full scale-0 flex-col gap-y-2 rounded-lg bg-white p-3',
        'dark:shadow-primary-md-dark dark:bg-dark',
        {
          '!scale-100': isOpen,
        },
      )}
      style={style}
    >
      {SETTING_MENU.slice(0, 2).map((props) => {
        return <MenuButton key={props.text} t={t} {...props} />
      })}

      <MenuButton t={t} {...SETTING_MENU[2]} />
      <ul
        className={cn(
          'flex w-full origin-top flex-wrap items-center gap-1 px-2 pl-8',
        )}
      >
        {COLORS.map((v) => {
          const _color = MAP_COLORS[v]

          return (
            <li
              key={v}
              className={cn('flex h-max w-max rounded-full p-1', {
                'border-2': v === themes.color,
                '!border-primary/0': v !== themes.color,
              })}
              style={{ borderColor: _color }}
            >
              <button
                style={{ backgroundColor: _color }}
                className={cn('h-4 w-4 rounded-full')}
                aria-label={String(t('common:change_to_color', { color: v }))}
                onClick={() => {
                  changeColorHandler(v)
                }}
              />
            </li>
          )
        })}
      </ul>

      <div>
        <MenuButton t={t} {...SETTING_MENU[3]} />
        <ul
          className={cn('flex w-full origin-top flex-col flex-wrap px-2 pl-8')}
        >
          {LANG.map(({ id, text }) => {
            const isCurrent = router.locale === id
            return (
              <li key={id} className={cn('w-full')}>
                <Link
                  href={{
                    pathname: router.pathname,
                    query: router.query,
                  }}
                  locale={id}
                  aria-disabled={isCurrent}
                  className={cn('block w-full rounded-sm p-1 px-2 text-sm', {
                    'cursor-default text-accent': isCurrent,
                    'text-primary hover:bg-light/60 dark:hover:bg-light/20':
                      !isCurrent,
                  })}
                >
                  {text}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default MenuComponent
